import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout";
import {
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardText,
  Form,
  FormGroup,
  Button,
  Input,
  Label
} from "reactstrap";
import { Link } from 'gatsby';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import { getContactsInfo, postContactsInfo } from '../../reducers/About/actions'
import { setModal } from '../../reducers/Home/actions'
import ConfirmationModal from '../../components/Modals/ConfirmationModal/ConfirmationModal'
import BePartnerModal from '../../components/Modals/BePartnerModal/BePartnerModal'
import { connect } from 'react-redux'
import { validateEmail, nameRegex } from '../../utils/util'
import { urlDescription } from '../../utils/pageDescription'
import "./styles.scss";

const Contacts = ({
  contacts,
  getContactsInfo,
  postContactsInfo,
  successModal,
  setModal }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isModalOpen, setModalOpen] = useState(false);
  const [validate, setValidate] = useState(false)

  const BePartnerModalHandler = () => {
    setModalOpen(!isModalOpen);
  };

  useEffect(() => {
    getContactsInfo()
  }, [getContactsInfo])

  const validateFields = () => {
    if (name === '' || !nameRegex.test(name) || !validateEmail(email) || message === '') {
      return true
    } else return false
  }

  let url = "";

  if (typeof window !== "undefined") {
    url = new URL(window.location.href);
  }

  return (
    <Layout description={urlDescription[url.pathname]}>
      <div className="contacts-wrapper">
        <div className="contacts-container">
          <h1 className='h2 contacts-container__title'>
            Връзка с нашия екип
          </h1>
        </div>
      </div>
      <div className="contacts-card-wrapper">
        <div className="contacts-card-container">
          <Row className="justify-content-center">
            {
              contacts &&
              contacts.map(c => (
                <Col key={c.id} sm={12} lg={5}>
                  <Card className='p-4 contacts-card'>
                    <CardImg
                      className='contacts-card__image'
                      src={`${process.env.GATSBY_XELLA_BACKEND}${c.image}`}
                      alt="Card image cap"
                    />
                    <CardBody>
                      <CardTitle tag="h2" className="h3 font-weight-bold contacts-card__title">{c.title}</CardTitle>
                      <CardText
                        style={{ cursor: 'pointer' }}
                        className='contacts-card__card-text'
                        dangerouslySetInnerHTML={c && { __html: c.description }}
                        onClick={BePartnerModalHandler}>
                      </CardText>
                      <div className='contacts-card__email-container'>
                        <EmailIcon id='email-icon' />
                        <Link style={{ cursor: 'pointer' }} className="no-underline blue-link text-left">
                          {c.email}
                        </Link>
                      </div>
                      <hr />
                      <div className='contacts-card__phone-container'>
                        <Row className="align-items-center">
                          <Col className='phone-icon-col'>
                            <PhoneIcon id='phone-icon' />
                          </Col>
                          <Col className='phone-desc-col'>
                            <h5>
                              {c.phone}
                            </h5>
                            <span>
                              {c.working_time}
                            </span>
                          </Col>
                        </Row>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))
            }
          </Row>
        </div>
        <div className="mt-5 contacts-question-container">
          <div className="contacts-question-container__text">
            <h3 className="h1 font-weight-bold">
              Контактна форма
            </h3>
            <div className='mt-5 mx-auto w-50 contacts-question-container__form-container'>
              <Form>
                <FormGroup>
                  <Label for="name">Име и фамилия</Label>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    onChange={(e) => setName(e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="email">Имейл адрес</Label>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormGroup>
                <FormGroup className='text-area mb-5'>
                  <Label for="message">Съобщение</Label>
                  <Input
                    type="textarea"
                    name="message"
                    id="message"
                    className='height-200'
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </FormGroup>
                
                <div className='mt-1'>
                  {
                    validateFields() && validate &&
                    <div className='data-alert margin-top15 mx-auto px-2'>
                      Моля попълнете коректно всички данни!
                    </div>
                  }
                </div>
                <div className="margin-top15 text-center">
                  <Button
                    className={"margin-top15 text-uppercase single-discount-btn shadow w-50"}
                    onClick={() => {
                      if (validateFields()) {
                        setValidate(true)
                        return
                      }
                      postContactsInfo({
                        name,
                        email,
                        description: message
                      })
                    }}
                  >
                    Изпрати съобщение
                  </Button>
                  
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <ConfirmationModal
        title={`Вашето съобщение е изпратено успешно. Ще
                получите отговор във възможно най-кратък срок.`}
        isOpen={successModal}
        onClick={() => setModal()}
      />
      <BePartnerModal
        isOpen={isModalOpen}
        title="Указания за регистрация"
        onClick={() => setModalOpen(!isModalOpen)}
      />
    </Layout>
  );
};

const mapStateToProps = state => ({
  contacts: state.aboutInfo.contacts,
  successModal: state.home.successModal
});

const mapDispatchToProps = {
  getContactsInfo,
  postContactsInfo,
  setModal
};

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
